<template>
  <div class="iframe">
    <iframe :srcdoc="htmlData" width="100%" height="100%" title="在线预览"/>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      htmlData: '',
    };
  },
  async mounted () {
    await this.setUserInfo()
    this.preview();
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    async setUserInfo() {
      if (this.$store.state.token) return Promise.resolve()
      const token = this.$route.query.token
      window.localStorage.setItem("token", token || "");
      this.setToken(token);
      return this.$http.Get(this.$api.getUserInfo, {}).then((res) => {
        window.localStorage.setItem("user", JSON.stringify(res.data));
        this.setUser(res.data);
        this.$emit('success', res.data)
      });
    },
    async preview() {
      const id = this.$route.query.id
      let res = await this.$http.Post(this.$api.clientPreviewAttachment, { id:id });
      if (res.code == 200) {
        this.htmlData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.iframe{
  height: 100vh;
  width: 100vh;
  iframe{
    width: 100%;
    height: 100%;
  }
}
</style>